import React, { useEffect, useState, useCallback } from "react";
import { Link } from "react-router-dom";
import { isEmpty, map } from "lodash";
import moment from "moment";
import {
  Badge,
  Button,
  Card,
  Col,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
  Nav,
  NavItem,
  NavLink,
  Offcanvas,
  OffcanvasBody,
  OffcanvasHeader,
  Row,
  Spinner,
  TabContent,
  TabPane,
  Toast,
  ToastBody,
} from "reactstrap";

import classnames from "classnames";
import { Bars } from "react-loader-spinner";

//Import Scrollbar
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";

import {
  createdThread,
  deleteThread,
  listThread,
  listThreadMessages,
  messageThread,
} from "src/services/chat";
import { useMutation, useQuery } from "@tanstack/react-query";
import { withTranslation } from "react-i18next";
import { motion } from "framer-motion";

interface CurrentUserTypes {
  name: string;
  isActive: boolean;
}

const Chat = (props: any) => {
  const [messages, setMessages] = useState([]);

  const [currentRoomId, setCurrentRoomId] = useState<any>();
  const [currentUser, setCurrentUser] = useState<CurrentUserTypes>({
    name: "usr",
    isActive: true,
  });

  const [settings_Menu, setsettings_Menu] = useState<boolean>(false);
  const [activeTab, setactiveTab] = useState<string>("1");
  const [Chat_Box_Username, setChat_Box_Username] = useState<string>("");

  const [curMessage, setcurMessage] = useState<string>("");

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingDel, setIsLoadingDel] = useState(false);
  const [listThred, setListThred] = useState(false);
  const [chatThread, setChatThread] = useState("");
  const [createdLoading, setCreatedLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [toast, setToast] = useState(false);
  const [msg, setMsg] = useState("");
  const [messageLoading, setMessageLoading] = useState(false);
  const [messageBox, setMessageBox] = useState<any>(null);
  const [open, setOpen] = useState(false);
  const [modal_center, setmodal_center_delete] = useState(false);

  const {
    data: threadList,
    isSuccess: isSuccessthreadList,
    refetch: refetchthreadList,
  }: any = useQuery({
    queryFn: listThread,
    queryKey: ["THREAD_LIST", 1],
  });

  const {
    data: historyChatThread,
    isSuccess: isSuccessHistory,
    refetch: refetchHistory,
  } = useQuery({
    queryFn: () => listThreadMessages(chatThread),
    queryKey: ["HISTORY_LIST", chatThread],
    enabled: !!chatThread,
  });

  useEffect(() => {
    setMessageLoading(true);
    if (isSuccessHistory) {
      setMessageLoading(false);
      //const lastMessage = historyChatThread.length - 1;

      /*if(lastMessage > 0 ) {
        setMessages((prevMessages): any => [...prevMessages, historyChatThread[lastMessage]]);
      }*/

      setMessages(historyChatThread);
    }
  }, [isSuccessHistory, historyChatThread]);

  useEffect(() => {
    if (isSuccessthreadList) {
      setListThred(true);
      setMessageLoading(false);
    }
  }, [isSuccessthreadList, threadList]);

  const createThreadMutation = useMutation({
    mutationKey: ["CREATE_THREAD"],
    mutationFn: createdThread,
    onSuccess: (data) => {
      refetchthreadList();
      setCreatedLoading(false);
    },
    onError: (err: any) => {
      setToast(true);
      const message = "Falha na solicitação, tente novamente";
      setMsg(message);
    },
  });

  const sendMessagedMutation = useMutation({
    mutationKey: ["SEND_MESSAGE"],
    mutationFn: messageThread,
    onSuccess: (data) => {
      setIsLoading(false);
      const lastMessage = data.messages.length - 1;
      if (lastMessage > 0) {
        setMessages((prevMessages): any => [
          ...prevMessages,
          data.messages[lastMessage],
        ]);
      }
      //refetchHistory();
    },
    onError: (err: any) => {
      setToast(true);
      const message = "Falha na solicitação, tente novamente";
      setMsg(message);
    },
  });

  const deleteThreadMutation = useMutation({
    mutationKey: ["DELETE_THREAD"],
    mutationFn: deleteThread,
    onSuccess: (data) => {      
      setmodal_center_delete(false);
      setIsLoadingDel(false);
      refetchthreadList();
      setListThred(false);
    },
    onError: (err: any) => {
      setToast(true);
      const message = "Falha na solicitação, tente novamente";
      setMsg(message);
    },
  });

  const toggleSettings = () => {
    setsettings_Menu(!settings_Menu);
  };

  const userChatOpen = (uuid: any, name: string) => {
    setChat_Box_Username(name);
    setCurrentRoomId(uuid);
  };

  useEffect(() => {
    if (listThred === true) {
      if (threadList && threadList.length > 0) {
        userChatOpen(
          threadList[0].uuid,
          threadList[0].name ? threadList[0].name : "Novo Chat"
        );
        setChatThread(threadList[0].uuid);
      } else {
        createThreadMutation.mutate();
        setListThred(false);
      }
    }
  }, [listThred]);

  const addMessage = (uuid: number, type: string) => {
    const message: any = {
      uuid,
      created_at: new Date(),
      external_message_id: "msg_glA2YvkGW9DiKPShW01y3Qs4",
      id: Math.floor(Math.random() * 100),
      text: curMessage,
      thread_id: Math.floor(Math.random() * 100),
      type,
    };

    setMessages((prevMessages): any => [...prevMessages, message]);
    setcurMessage("");
  };

  const onKeyPress = (e: any) => {
    const { key, value } = e;
    if (key === "Enter" && curMessage !== "" && chatThread !== "") {
      setcurMessage(value);
      addMessage(currentRoomId, currentUser.name);
      handleMessage();
    }
  };

  const handleMessage = () => {
    setIsLoading(true);
    const messagePayload = {
      uuid: chatThread,
      message: curMessage,
    };

    sendMessagedMutation.mutate(messagePayload);
  };

  const handleDeleteThread = () => {
    setIsLoadingDel(true)
    const thread = {
      uuid: chatThread,
    };

    deleteThreadMutation.mutate(thread);
  };

  const handleButtonClick = () => {
    setListThred(false);
    createThreadMutation.mutate();
  };

  const isValidThreadList = Array.isArray(threadList) && threadList.length > 0;

  const filteredThreadList = isValidThreadList
    ? threadList.filter(
        (chat: any) =>
          chat.contacts[0]?.email
            ?.toLowerCase()
            .includes(searchTerm.toLowerCase()) ||
          "Novo Chat".toLowerCase().includes(searchTerm.toLowerCase())
      )
    : [];

  const scrollToBottom = useCallback(() => {
    if (messageBox) {
      messageBox.scrollTop = messageBox.scrollHeight + 1000;
    }
  }, [messageBox]);

  useEffect(() => {
    if (!isEmpty(messages)) scrollToBottom();
  }, [messages, scrollToBottom]);

  const toggleLeftCanvas = () => {
    setOpen(!open);
  };

  function tog_center_delete() {
    setmodal_center_delete(!modal_center);
  }

  return (
    <div className="d-lg-flex h-100">
      <Card
        className="chat-leftsidebar d-none d-sm-block"
        style={{ marginBottom: "0px" }}
      >
        <div className="p-3">
          <div className="search-box position-relative">
            <input
              type="text"
              className="form-control rounded border"
              placeholder="Pesquisar..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <i className="bx bx-search search-icon"></i>
          </div>
        </div>

        <div className="chat-leftsidebar-nav d-none d-sm-block">
          <Nav
            pills
            justified
            className="bg-soft-light p-1 nav nav-pills nav-justified bg-light-subtle p-1"
          >
            <NavItem>
              <NavLink
                className={classnames({
                  active: activeTab === "1",
                })}
                onClick={() => {
                  handleButtonClick();
                  setCreatedLoading(true);
                }}
              >
                <span style={{ cursor: "pointer" }}>
                  {createdLoading ? (
                    <Spinner
                      size="sm"
                      className="ms-1"
                      style={{ color: "#0073cf", marginTop: "3px" }}
                    />
                  ) : (
                    <>{props.t("Novo Chat")} </>
                  )}
                </span>
              </NavLink>
            </NavItem>
          </Nav>
          <Offcanvas
            isOpen={open}
            toggle={toggleLeftCanvas}
            fade={true}
            direction="start"
          >
            <OffcanvasHeader toggle={toggleLeftCanvas}>Chat</OffcanvasHeader>
            <OffcanvasBody>
              <Nav
                pills
                justified
                className="bg-soft-light p-1 nav nav-pills nav-justified bg-light-subtle p-1"
              >
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: activeTab === "1",
                    })}
                    onClick={() => {
                      handleButtonClick();
                      setCreatedLoading(true);
                    }}
                  >
                    <span style={{ cursor: "pointer" }}>
                      {createdLoading ? (
                        <Spinner
                          size="sm"
                          className="ms-1"
                          style={{ color: "#fff", marginTop: "3px" }}
                        />
                      ) : (
                        <>{props.t("Novo Chat")} </>
                      )}
                    </span>
                  </NavLink>
                </NavItem>
              </Nav>
              <div className="p-1">
                <div className="search-box position-relative">
                  <input
                    type="text"
                    className="form-control rounded border"
                    placeholder="Pesquisar..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                  <i className="bx bx-search search-icon"></i>
                </div>
              </div>
              <PerfectScrollbar style={{ height: "auto" }}>
                <div className="pt-3">
                  <ul className="list-unstyled chat-list">
                    {filteredThreadList.length > 0 ? (
                      filteredThreadList.map((chat: any) => (
                        <li
                          key={chat.uuid}
                          className={
                            currentRoomId === chat.uuid ? "active" : ""
                          }
                        >
                          <Link
                            to="#"
                            onClick={() => {
                              userChatOpen(
                                chat.uuid,
                                chat.contacts[0]?.name || "Novo Chat"
                              );
                              setChatThread(chat.uuid);
                              setOpen(false);
                            }}
                          >
                            <div className="d-flex align-items-start">
                              <div className="flex-grow-1 overflow-hidden">
                                <h5 className="text-truncate font-size-14 mb-1">
                                  {chat.contacts && chat.contacts.length > 0 ? (
                                    chat.contacts[0].email
                                  ) : (
                                    <>{props.t("Novo Chat")} </>
                                  )}
                                </h5>
                                {chat.status === "pen" && (
                                  <Badge className="bg-warning">
                                    {props.t("Pendente")}
                                  </Badge>
                                )}
                                {chat.status === "wai" && (
                                  <Badge className="bg-info">
                                    {props.t("Aguardando Resposta")}
                                  </Badge>
                                )}
                                {chat.status === "com" && (
                                  <Badge className="bg-success">
                                    {props.t("Completo")}
                                  </Badge>
                                )}
                              </div>
                              <div className="flex-shrink-0">
                                <div className="font-size-11">
                                  {/*chat.time*/}
                                </div>
                              </div>
                            </div>
                          </Link>
                        </li>
                      ))
                    ) : (
                      <></>
                    )}
                  </ul>
                </div>
              </PerfectScrollbar>
            </OffcanvasBody>
          </Offcanvas>
          <TabContent activeTab={activeTab}>
            <TabPane tabId="1">
              <PerfectScrollbar
                className="d-none d-sm-block"
                style={{ height: "500px" }}
              >
                <div className="pt-3">
                  <ul className="list-unstyled chat-list">
                    {filteredThreadList.length > 0 ? (
                      filteredThreadList.map((chat: any) => (
                        <li
                          key={chat.uuid}
                          className={
                            currentRoomId === chat.uuid ? "active" : ""
                          }
                        >
                          <Link
                            to="#"
                            onClick={() => {
                              userChatOpen(
                                chat.uuid,
                                chat.contacts[0]?.name || props.t("Novo Chat")
                              );
                              setChatThread(chat.uuid);
                            }}
                          >
                            <div className="d-flex align-items-start">
                              <div className="flex-grow-1 overflow-hidden">
                                <h5 className="text-truncate font-size-14 mb-1">
                                  {chat.contacts && chat.contacts.length > 0 ? (
                                    chat.contacts[0].email
                                  ) : (
                                    <>{props.t("Novo Chat")} </>
                                  )}
                                </h5>
                                {chat.status === "pen" && (
                                  <Badge className="bg-warning">
                                    {props.t("Pendente")}
                                  </Badge>
                                )}
                                {chat.status === "wai" && (
                                  <Badge className="bg-info">
                                    {props.t("Aguardando Resposta")}
                                  </Badge>
                                )}
                                {chat.status === "com" && (
                                  <Badge className="bg-success">
                                    {props.t("Completo")}
                                  </Badge>
                                )}
                              </div>
                            </div>
                          </Link>
                        </li>
                      ))
                    ) : (
                      <></>
                    )}
                  </ul>
                </div>
              </PerfectScrollbar>
            </TabPane>
          </TabContent>
        </div>
      </Card>
      <div className="w-100 h-100 user-chat mb-0">
        <Card className="h-100">
          <div
            className="display: flex; border-bottom"
            style={{ height: "10%" }}
          >
            <Row style={{padding: '0 12px', margin: '0'}} className="w-100 d-flex align-items-center h-100">
              <Col className="d-sm-none d-flex align-items-center justify-content-start">
                <button
                  onClick={() => {
                    toggleLeftCanvas();
                  }}
                  type="button"
                  className="btn btn-sm font-size-16"
                >
                  <i className="fa fa-fw fa-bars"></i>
                </button>
              </Col>
              <Col className="d-flex align-items-center justify-content-end justify-content-sm-start">
                <div className="d-flex justify-content-center align-items-center">
                  <div className="flex-grow-1">
                    <h5 className="font-size-14 text-truncate mb-0">
                      {Chat_Box_Username}
                    </h5>
                  </div>
                </div>
              </Col>
              <Col className="align-items-center justify-content-end">
                <ul className="list-inline user-chat-nav text-end mb-0">
                  <li className="list-inline-item">
                    <Dropdown isOpen={settings_Menu} toggle={toggleSettings}>
                      <DropdownToggle className="btn nav-btn" tag="i">
                        <i className="bx bx-cog" />
                      </DropdownToggle>
                      <DropdownMenu className="dropdown-menu-end">
                        <DropdownItem onClick={() => tog_center_delete()}>
                          {props.t("Deletar")}{" "}
                        </DropdownItem>
                      </DropdownMenu>
                    </Dropdown>
                  </li>
                </ul>
                <Modal
                  isOpen={modal_center}
                  toggle={() => {
                    tog_center_delete();
                  }}
                  centered={true}
                >
                  <div className="modal-header">
                    <h5 className="modal-title mt-0">Excluir chat</h5>
                    <button
                      type="button"
                      onClick={() => {
                        setmodal_center_delete(false);
                      }}
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <p>
                      Isso não pode ser desfeito. Todas as memórias criadas
                      serão perdidas.
                    </p>
                    <div className="d-flex gap-3">
                      <button
                        type="button"
                        className="btn btn-soft-danger waves-effect waves-light w-50"
                        onClick={() => {
                          setmodal_center_delete(false);
                        }}
                      >
                        Cancelar
                      </button>
                      <button
                        type="button"
                        className="btn btn-soft-success waves-effect waves-light w-50"
                        onClick={() => handleDeleteThread()}
                        disabled={isLoadingDel}
                      >
                        {isLoadingDel ? (
                          <div className="label-icon">
                            <Spinner
                              size="sm"
                              className=""
                              style={{
                                color: "#2ab57d",
                                marginTop: "3px",
                              }}
                            />
                          </div>
                        ) : (
                          <>
                            Excluir
                          </>
                        )}
                        
                      </button>
                    </div>
                  </div>
                </Modal>
              </Col>
            </Row>
          </div>

          <div style={{ height: "90%" }}>
            <PerfectScrollbar
              className="chat-conversation p-1 px-1"
              style={{ height: "88%" }}
              containerRef={(ref) => setMessageBox(ref)}
            >
              <ul className="list-unstyled">
                <li>
                  <div className="chat-day-title">
                    <span className="title">Hoje</span>
                  </div>
                </li>

                {messageLoading ? (
                  <div className="w-100 d-flex justify-content-center align-items-center">
                    <Spinner
                      size="sm"
                      className="ms-1"
                      style={{ color: "#0073cf", marginTop: "3px" }}
                    />
                  </div>
                ) : messages && messages.length > 0 ? (
                  messages.map((message: any) => (
                    <>
                      <motion.li
                        key={"test_k" + message.id}
                        className={message.type === "usr" ? "right" : ""}
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ duration: 0.5 }}
                      >
                        <div className="conversation-list">
                          <div className="ctext-wrap">
                            <div className="ctext-wrap-content">
                              <h5 className="conversation-name">
                                <Link to="#" className="user-name">
                                  {message.type === "usr" ? "Eu" : "Aime"}
                                </Link>
                                <span className="time">
                                  {moment(message.created_at).format(
                                    "DD/MM HH:mm"
                                  )}
                                </span>
                              </h5>
                              <p className="mb-0">{message.text}</p>
                            </div>
                          </div>
                        </div>
                      </motion.li>
                    </>
                  ))
                ) : (
                  <></>
                )}
                {isLoading && (
                  <li className="left">
                    <div className="conversation-list">
                      <div className="ctext-wrap">
                        <div className="ctext-wrap-content">
                          <h5 className="conversation-name">
                            <Link to="#" className="user-name">
                              Aime
                            </Link>
                          </h5>
                          <Bars
                            height="30"
                            width="30"
                            color="#fff"
                            ariaLabel="bars-loading"
                            wrapperStyle={{}}
                            wrapperClass=""
                            visible={true}
                          />
                        </div>
                      </div>
                    </div>
                  </li>
                )}
              </ul>
            </PerfectScrollbar>
            <div
              className="p-2 border-top d-flex justify-content-center align-items-center"
              style={{ height: "12%" }}
            >
              <Row className="w-100">
                <Col>
                  <div className="position-relative">
                    <input
                      type="text"
                      value={curMessage}
                      onKeyDown={onKeyPress}
                      onChange={(e) => setcurMessage(e.target.value)}
                      className="form-control border bg-light-subtle"
                      placeholder="Escreva sua mensagem..."
                    />
                  </div>
                </Col>
                <Col className="col-auto">
                  <Button
                    type="button"
                    color="primary"
                    onClick={() => {
                      if (curMessage !== "") {
                        handleMessage();
                        addMessage(currentRoomId, currentUser.name);
                      }
                    }}
                    className="btn btn-primary chat-send w-md"
                    disabled={chatThread === "" ? true : isLoading}
                  >
                    <span className="d-none d-sm-inline-block me-2">
                      {isLoading ? (
                        <Spinner
                          size="sm"
                          className="ms-1"
                          style={{ color: "#FFF", marginTop: "3px" }}
                        />
                      ) : (
                        <>Enviar</>
                      )}
                    </span>{" "}
                    <i className="mdi mdi-send float-end" />
                  </Button>
                </Col>
              </Row>
            </div>
          </div>
        </Card>
      </div>
      <div className="position-fixed top-0 end-0 p-3" style={{ zIndex: 9999 }}>
        <Toast
          isOpen={toast}
          id="borderedToast1"
          className="toast-border-primary overflow-hidden mt-3"
        >
          <ToastBody className="bg-danger text-white">
            <div className="d-flex align-items-center">
              <div className="flex-shrink-0 me-2">
                <i className="ri-user-smile-line align-middle"></i>
              </div>
              <div className="flex-grow-1">
                <h6 className="mb-0 text-white">{msg}</h6>
              </div>
              <button
                type="button"
                className="btn-close btn-close-white me-2 m-auto"
                data-bs-dismiss="toast"
                aria-label="Close"
                onClick={() => {
                  setToast(false);
                  window.location.reload();
                }}
              ></button>
            </div>
          </ToastBody>
        </Toast>
      </div>
    </div>
  );
};

export default withTranslation()(Chat);
