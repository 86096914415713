import React from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "src/hooks/signIn";


const Authmiddleware = (props:any) => {
  const { isAuthenticated } = useAuth();

  if (!isAuthenticated()) {
    return (
      <Navigate to={{ pathname: "/login"}} />
    );
  }
  return <React.Fragment>{props.children}</React.Fragment>;
};

export default Authmiddleware;
