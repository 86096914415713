import usFlag from "../assets/images/flags/us.jpg"
import brasil from "../assets/images/flags/br.jpg"

const languages : Object = {
  ptbr: {
    label: "Brasil",
    flag: brasil,
  },
  en: {
    label: "English",
    flag: usFlag,
  },
};

export default languages
