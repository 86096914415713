import React, { useEffect } from "react";
import withRouter from "../../components/Common/withRouter";
import { withTranslation } from "react-i18next";

//redux
import { useDispatch } from "react-redux";
import { Col, Container, Row } from "reactstrap";
import { Link } from "react-router-dom";
import CarouselPage from "../AuthenticationInner/CarouselPage";
import logo from "../../assets/images/logo-sm.svg";
import { useAuth } from "src/hooks/signIn";

const Logout = (props: any) => {
  const dispatch = useDispatch();
  const { signOut } = useAuth();
  useEffect(() => {
    signOut();
  }, [dispatch, history]);

  return (
    <div className="container-fluid p-0">
      <Container fluid>
        <Row className="row g-0">
          <Col xxl={4} lg={4} md={5}>
            <div className="auth-full-page-content d-flex p-sm-5 p-4">
              <div className="w-100">
                <div className="d-flex flex-column h-100">
                  <div className="mb-4 mb-md-5 text-center">
                    <Link to="index.html" className="d-block auth-logo">
                      <img src={logo} alt="" height="45" />{" "}
                    </Link>
                  </div>
                  <div className="auth-content my-auto">
                    <div className="text-center">
                      <div className="avatar-xl mx-auto">
                        <div className="avatar-title bg-light-subtle text-primary h1 rounded-circle">
                          <i className="bx bxs-user"></i>
                        </div>
                      </div>

                      <div className="mt-4 pt-2">
                        <h5>{props.t("Você está desconectado")}</h5>
                        <p className="text-muted font-size-15">
                        {props.t("Obrigado por usar")}  {" "}
                          <span className="fw-semibold text-dark">Aime</span>
                        </p>
                        <div className="mt-4">
                          <a
                            href="/"
                            className="btn btn-primary w-100 waves-effect waves-light"
                          >
                            {props.t("Entrar")}
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mt-4 mt-md-5 text-center">
                    <p className="mb-0">
                      © {new Date().getFullYear()} {props.t("Aime. Feito com")} {" "}
                      <i className="mdi mdi-heart text-danger"></i>{" "}
                      Essência Digital
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <CarouselPage />
        </Row>
      </Container>
    </div>
  );
};

export default withTranslation()(withRouter(Logout));
