import api from "./api";

export async function googleAuth(token: string | null) {
  const { data } = await api.get("/google/authorization", {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/x-www-form-application/json",
    },
  });

  return data;
}

export async function googleCode(code: string | null) {
  try {
    const { data } = await api.put(`/google/authorization/token?code=${code}`);
    return data;
  } catch (error) {
    console.error(error);
  }
}

type LoginWhatsapp = {
  user_uuid: string | null;
  token: string | null;
};

export async function whatsappLogin(props: LoginWhatsapp) {
  const { user_uuid, ...rest } = props;
  const { data } = await api.post(
    `/user/auth/token?user_uuid=${user_uuid}`,
    rest
  );
  return data;
}

export async function googleDisconnect() {
  try {
    const { data } = await api.put(`/google/authorization/revoke`);
    return data;
  } catch (error) {
    console.error(error);
  }
}

export async function wppConnect(wpp: string | null) {
  const { data } = await api.put(`/user/cellphone`, {
    cell_phone: wpp,
  });
  return data;
}
