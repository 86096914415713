import { combineReducers } from "redux"

// Layout
import Layout from "./layout/reducer";

const rootReducer = combineReducers({
  // public
  Layout,
})

export default rootReducer
