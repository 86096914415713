import api from "./api";

export type ResponseListContacts = {
  data: {
    name: string;
    id: number;
    cell_phone: string;
    user_id: number;
    email: string;
    uuid: string;
    created_at: string;
  }[];
};

export type ResponseListThread = {
  data: {
    uuid: string;
    contacts: {
      uuid: string;
      name: string;
      email: string;
    }[];
  }[];
};

export type responseListThreadMessages = {
  created_at: string;
  external_message_id: string;
  id: number
  text: string;
  thread_id: number
  type: string;
  uuid: string;
}

export async function listContacts() {
  const { data } = await api.get<ResponseListContacts>(`/contact/list`);

  return data;
}

export async function listThreadMessages(uuid: string | null) {
  const { data } = await api.get(`/openai/thread/:thread_uuid/messages?thread_uuid=${uuid}`);

  return data;
}

export async function listThread() {
  const { data } = await api.get<ResponseListThread>(`/openai/thread/list`);

  return data;
}

export async function createdThread() {
  const { data } = await api.post("/openai/thread/create");
  return data;
}

type MessageThread = {
  uuid: string;
  message: string;
};

export async function messageThread(props: MessageThread) {
  const { uuid, ...rest } = props;
  const { data } = await api.post(
    `/openai/thread/:thread_uuid/messaging?thread_uuid=${uuid}`,
    rest
  );
  return data;
}

type DeleteThread = {
  uuid: string;
};

export async function deleteThread(props: DeleteThread) {
  const { uuid } = props;
  const { data } = await api.delete(
    `/openai/thread/:thread_uuid?thread_uuid=${uuid}`,
  );

  return data;
}
