import api from './api';

type LoginProps = {
  email: string;
  password: string;
  name: string;
};

interface AuthProps {
  UserEmail: string;
  UserPassword: string;
}

export async function registerUser(props: LoginProps) {
  const { data } = await api.post('/user/register', props);
  return data;
}

export async function oauthLogin({ UserEmail, UserPassword }: AuthProps) {
  try {
    const response  = await api.post(
      '/user/login',
      {
        client_id: process.env.REACT_APP_CLIENT_ID,
        client_secret: process.env.REACT_APP_SECRET_ID,
        grant_type: 'password',
        username: UserEmail,
        password: UserPassword,
        scope: 'gam_user_data',
      },
      {
        headers: {
          'Content-Type' : 'application/x-www-form-urlencoded',
         }
      }
    );

    return response;
  } catch (err: any) {
    return err;
  }
}
