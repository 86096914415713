import React, { useEffect, useState } from "react";

//import Breadcrumbs
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Card, CardBody, Col, Container, Row } from "reactstrap";

// import common data
import Chat from "src/components/Common/Chat";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const Aime = (props: any) => {
  const [learning, setLearning] = useState<boolean>(false);
  document.title = props.t("Aime | Aime");

  const checkLearningStatus = () => {
    const hasGoogle = localStorage.getItem("has_google");
    const hasLearned = localStorage.getItem("has_learned");

    if (hasGoogle === "yes" && hasLearned === "yes") {
      setLearning(true);
    } else {
      setLearning(false);
    }
  };

  useEffect(() => {
    checkLearningStatus();

    const handleStorageChange = (e: StorageEvent) => {
      if (e.key === "has_google" || e.key === "has_learned") {
        checkLearningStatus();
      }
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  return (
    <React.Fragment>
      <div className="page-content" style={{height: '90vh'}}>
        <Container fluid className="h-100">
          {!learning ? (
            <Row>
              <Col lg={12}>
                <Card>
                  <CardBody>
                    <Row className="justify-content-center mt-3">
                      <div className="col-xl-5 col-lg-8">
                        <div className="text-center">
                          <h5>Conecte seu Google</h5>
                          <p className="text-muted">
                            Para que você consiga usar o Aime, conecte seu Google
                          </p>
                          <div>
                          <Link
                            to="/steps"
                            className="btn btn-primary mt-2 me-2 waves-effect waves-light"
                          >
                            Conectar
                            </Link>
                          </div>
                        </div>
                      </div>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          ) : (
            <Chat />
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withTranslation()(Aime);
