import axios from 'axios';

const token: string | null = localStorage.getItem("@aime:token"); 

const api = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

api.defaults.headers.common['Authorization'] = `Bearer ${token}`;

export default api;
