import React, { useContext, createContext, useState, useEffect, useCallback } from 'react';
import api from 'src/services/api';

interface LoginProps {
  UserEmail: string;
  UserPassword: string;
}

interface AuthContextData {
  handleAuth(data: any): void;
  signOut(): void;
  isAuthenticated(): boolean;
}

type jwtProps = {
  iss: string;
  iat: number;
  exp: number;
  nbf: number;
  jti: string;
  sub: string;
  prv: string;
};

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

const AuthProvider: React.FC<{ children: any }> = ({ children }) => {

  function isAuthenticated() {
    const token: any = localStorage.getItem('@aime:token');
    const exp: any = localStorage.getItem('@aime:exp');

    if (!token || !exp) {
      return false;
    }

    const now: number = Date.now();
    
    if(now > new Date(exp).getTime()) {
      localStorage.removeItem('@aime:access_token');
      localStorage.removeItem('@aime:exp');
      return false;
    }

    return true;
  }

  const handleAuth = useCallback((data: any) => {

    api.defaults.headers.common['Authorization'] = `Bearer ${data.access_token}`;
      if(data.status === '00') {
        localStorage.setItem('@aime:token', data.access_token);
        localStorage.setItem('@aime:exp', data.exp);
        localStorage.setItem('has_google', data.has_google);
        localStorage.setItem('has_learned', data.has_learned);
        localStorage.setItem('email', data.email);
        localStorage.setItem('name', data.name);
        if(data.cell_phone) {
          localStorage.setItem('cell_phone', data.cell_phone);
        }
        
      } 
  }, []);

  async function signOut() {
    localStorage.removeItem('@aime:token');
    localStorage.removeItem('@aime:exp');
    localStorage.removeItem('has_google');
    localStorage.removeItem('has_learned');
    localStorage.removeItem('email');
    localStorage.removeItem('cell_phone');
    localStorage.removeItem('name');
    localStorage.removeItem('login_whatsapp');
  }

  return (
    <AuthContext.Provider
      value={{ handleAuth, signOut, isAuthenticated }}
    >
      {children}
    </AuthContext.Provider>
  );
};

function useAuth() {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider.');
  }

  return context;
}

export { useAuth, AuthProvider };
