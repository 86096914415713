import { useMutation } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import { whatsappLogin } from "src/services/googleAuth";
import { useAuth } from "src/hooks/signIn";


const LoginWhatsapp = () => {

  // CAPTURA DA URL
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const user = queryParams.get('user');
  const token = queryParams.get('token');
  const { handleAuth } = useAuth();
  const navigate = useNavigate();
  
  const [msg, setMsg] = useState("");


  const loginMutation = useMutation({
    mutationKey: ['CODE_GOOGLE'],
    mutationFn: whatsappLogin,
    onSuccess: (data: any) => {
      handleAuth(data);
      localStorage.setItem('login_whatsapp', 'sim');
      navigate('/steps');
    },
    onError: (err: any) => {
      setMsg('Erro ao tentar logar, tente novamente.')
    },
  }); 

  useEffect(() => {
    const loginPayload = {
      user_uuid: user,
      token: token,
    };
    loginMutation.mutate(loginPayload);
  }, [])

  return (
    <>
      {msg && (
        <>{msg}</>
      )}
    </>
  )
}

export default LoginWhatsapp;
