import React, { useState } from "react";
import {
  Row,
  Col,
  Container,
  Form,
  Input,
  FormFeedback,
  Label,
  Alert,
  Spinner,
} from "reactstrap";

import PropTypes from "prop-types";

import { Link, useNavigate } from "react-router-dom";
import withRouter from "../../components/Common/withRouter";
import { useAuth } from "../../hooks/signIn";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

// import images
import logo from "../../assets/images/logo-sm.svg";

//Import config
import CarouselPage from "../AuthenticationInner/CarouselPage";
import { withTranslation } from "react-i18next";
import * as auth from "../../services/auth";

const Login: React.FC = (props: any) => {
  const [msg, setMsg] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [status, setStatus] = useState<"success" | "danger">();

  const { handleAuth } = useAuth();
  const navigate = useNavigate();

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().required(props.t("Por favor preencher o e-mail")),
      password: Yup.string().required(props.t("Por favor insira sua senha")),
    }),
    onSubmit: async (values) => {
      setMsg("");
      setIsLoading(true);
      try {
        const res: any = await auth.oauthLogin({
          UserEmail: values.email,
          UserPassword: values.password,
        });

        if (typeof res.data !== "undefined" && res.data.status === "00") {
          handleAuth({ ...res.data });
          navigate("/aime");
          setIsLoading(false);
        } else {
          setIsLoading(false);
          setStatus("danger");
          setMsg(res.response.data.message);
          setIsLoading(false);
        }
      } catch (err: any) {
        setIsLoading(false);
        setStatus("danger");
        setMsg(props.t("Não foi possível realizar o login, tente novamente."));
      }
    },
  });

  document.title = "Login | Aime";

  return (
    <React.Fragment>
      <div className="auth-page">
        <Container fluid className="p-0">
          <Row className="g-0">
            <Col lg={4} md={4} className="col-xxl-4">
              <div className="auth-full-page-content d-flex p-sm-5 p-4">
                <div className="w-100">
                  <div className="d-flex flex-column h-100">
                    <div className="mb-4 mb-md-5 text-center">
                      <Link to="/aime" className="d-block auth-logo">
                        <img src={logo} alt="" height="45" />{" "}
                      </Link>
                    </div>
                    <div className="auth-content my-auto">
                      <div className="text-center">
                        <h5 className="mb-0">{props.t("Bem Vindo")}</h5>
                        <p className="text-muted mt-2">
                          {props.t("Faça login para continuar no Aime.")}
                        </p>
                      </div>
                      <Form
                        className="custom-form mt-4 pt-2"
                        onSubmit={(e) => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                      >
                        {msg && msg ? (
                          <Alert color={status}>{msg}</Alert>
                        ) : null}
                        <div className="mb-3">
                          <Label className="form-label">
                            {props.t("Email")}
                          </Label>
                          <Input
                            name="email"
                            className="form-control"
                            placeholder="Email"
                            type="email"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.email || ""}
                            invalid={
                              validation.touched.email &&
                              validation.errors.email
                                ? true
                                : false
                            }
                          />
                          {validation.touched.email &&
                          validation.errors.email ? (
                            <FormFeedback type="invalid">
                              {validation.errors.email}
                            </FormFeedback>
                          ) : null}
                        </div>

                        <div className="mb-3">
                          <Label className="form-label">
                            {props.t("Senha")}
                          </Label>
                          <Input
                            name="password"
                            value={validation.values.password || ""}
                            type="password"
                            placeholder={props.t("Senha")}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            invalid={
                              validation.touched.password &&
                              validation.errors.password
                                ? true
                                : false
                            }
                          />
                          {validation.touched.password &&
                          validation.errors.password ? (
                            <FormFeedback type="invalid">
                              {validation.errors.password}
                            </FormFeedback>
                          ) : null}
                        </div>

                        <div className="row mb-4">
                          <div className="col">

                            <div className="mb-4">
                              <p className="mb-0">
                                {props.t(
                                  "Ao usar o Aime você concorda a"
                                )}{" "}
                                <a
                                  href="https://hubes.ai/aime/pt/privacy"
                                  target="_blank"
                                  rel="noreferrer"
                                  className="text-primary"
                                >
                                  {props.t("Política de privacidade")}
                                </a>
                              </p>
                            </div>
                            <div className="mt-3 d-grid">
                              <button
                                className="btn btn-primary btn-block"
                                type="submit"
                                // onClick={() => logIn()}
                                disabled={isLoading}
                              >
                                {isLoading ? (
                                  <Spinner
                                    size="sm"
                                    className="ms-1"
                                    style={{ color: "#FFF", marginTop: "3px" }}
                                  />
                                ) : (
                                  props.t("Entrar")
                                )}
                              </button>
                            </div>
                          </div>
                        </div>
                      </Form>

                      <div className="mt-5 text-center">
                        <p className="text-muted mb-0">
                          {props.t("Não tem uma conta?")}{" "}
                          <Link
                            to="/register"
                            className="text-primary fw-semibold"
                          >
                            {" "}
                            {props.t("Inscreva-se agora")}{" "}
                          </Link>{" "}
                        </p>
                      </div>
                    </div>
                    <div className="mt-4 mt-md-5 text-center">
                      <p className="mb-0">
                        © {new Date().getFullYear()}{" "}
                        {props.t("Aime. Feito com")}{" "}
                        <i className="mdi mdi-heart text-danger"></i> Essência
                        Digital
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <CarouselPage />
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withTranslation()(withRouter(Login));
Login.propTypes = {
  history: PropTypes.object,
};
