import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

//i18n
import { withTranslation } from "react-i18next";
// Redux
import { Link } from "react-router-dom";

// users
import user1 from "../../../assets/images/users/avatar-1.jpg";

const ProfileMenu = (props: any) => {
  const [menu, setMenu] = useState<boolean>(false);

  const [username, setusername] = useState("Admin");
  const name: string | null  = localStorage.getItem('name');

  const getFirstLetter = (name: string | null) => {
    return name ? name.charAt(0).toUpperCase() : '';
  }

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item bg-soft-light border-start border-end"
          id="page-header-user-dropdown"
          tag="button"
        >
          <div className="d-flex align-items-center">
            <div className="avatar-sm flex-shrink-0 me-1">
              <div className="avatar-title bg-soft-light text-light rounded-circle font-size-16">
                {name ? (
                  getFirstLetter(name)
                ) : (
                  <i className="bx bxs-user-circle"></i>
                )}
              </div>
              {/** 
               * 
                <img
                  className="rounded-circle header-profile-user"
                  src={user1}
                  alt="Header Avatar"
                />
               */}
            </div>
            <div className="flex-grow-1">
              <div>
                <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
              </div>
            </div>
          </div>
        </DropdownToggle>

        <DropdownMenu className="dropdown-menu-end">
          <Link to="/logout" className="dropdown-item">
            <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
            <span>{props.t("Sair")}</span>
          </Link>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any,
};
export default withTranslation()(ProfileMenu);
