import React, { useState, useEffect } from "react";
import { Bars, TailSpin } from "react-loader-spinner";
import { motion, AnimatePresence } from "framer-motion";
import { Container, Row } from "reactstrap";

const texts = [
  "Em breve vecê poderá usar",
  "Nosso robô está aprendendo",
  "Aguarde um momento, estamos configurando",
];

const Loader: React.FC = () => {
  const [index, setIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setIndex((prevIndex) => (prevIndex + 1) % texts.length);
    }, 2000);

    return () => clearInterval(interval);
  }, []);

  return (
    <Container fluid>
      <Row>
        <div className="d-flex justify-content-center flex-column align-items-center p-3 mb-4">
          <Bars
            height="80"
            width="80"
            color="#4fa94d"
            ariaLabel="bars-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
          <div
            style={{
              position: "relative",
              height: "40px",
              marginTop: "20px",
              width: "100%",
            }}
          >
            <AnimatePresence>
              <motion.div
                key={index}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 1 }}
                style={{
                  position: "absolute",
                  width: "100%",
                  fontSize: "18px",
                  textAlign: "center",
                }}
              >
                {texts[index]}
              </motion.div>
            </AnimatePresence>
          </div>
        </div>
      </Row>
    </Container>
  );
};

export default Loader;
