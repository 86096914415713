import { useMutation } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import { googleCode } from "src/services/googleAuth";

const GoogleAuthentication = () => {

  // CAPTURA O CODE DA URL
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const code = queryParams.get('code');
  const [msg, setMsg] = useState("");


  const codeMutation = useMutation({
    mutationKey: ['CODE_GOOGLE'],
    mutationFn: googleCode,
    onSuccess: data => {
      localStorage.setItem('has_google', 'yes');
      window.close();
    },
    onError: (err: any) => {
      setMsg(err.response.data.detail)
    },
  });

  useEffect(() => {
    codeMutation.mutate(code);
  }, [])

  return (
    <>
      {msg && (
        <>{msg}</>
      )}
    </>
  )
}

export default GoogleAuthentication;
