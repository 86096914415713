import React, { useState } from "react";
import {
  Row,
  Col,
  Alert,
  Container,
  Input,
  Label,
  Form,
  FormFeedback,
  Spinner,
} from "reactstrap";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

import { registerUser } from "src/services/auth";

import { Link } from "react-router-dom";

// import images
import logo from "../../assets/images/logo-sm.svg";
import CarouselPage from "../AuthenticationInner/CarouselPage";
import { withTranslation } from "react-i18next";

const Register = (props: any) => {
  const [msg, setMsg] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [status, setStatus] = useState<"success" | "danger">();
  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      email: "",
      name: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().required(props.t("Por favor preencher seu Email")),
      name: Yup.string().required(props.t("Por favor preencher seu Nome")),
      password: Yup.string().required(props.t("Por favor insira sua senha")),
    }),
    onSubmit: async (values) => {
      setMsg("");
      setIsLoading(true);
      try {
        const response = await registerUser(values);
        setIsLoading(false);

        if (response.status === "00") {
          setMsg(response.message || "Usuário registrado com sucesso");
          setStatus("success");
        } else {
          setMsg(response.message || "Não foi possível efetuar o cadastro");
          setStatus("danger");
        }
      } catch (err: any) {
        console.error(err);
        setMsg(
          err.response?.data?.message || "Houve um erro ao efetuar o cadastro"
        );
        setStatus("danger");
        setIsLoading(false);
      }
    },
  });

  document.title = props.t("Criar conta | Aime - Reuniões");

  return (
    <React.Fragment>
      <div className="auth-page">
        <Container fluid className="p-0">
          <Row className="g-0">
            <Col lg={4} md={5} className="col-xxl-4">
              <div className="auth-full-page-content d-flex p-sm-5 p-4">
                <div className="w-100">
                  <div className="d-flex flex-column h-100">
                    <div className="mb-4 mb-md-5 text-center">
                      <Link to="/aime" className="d-block auth-logo">
                        <img src={logo} alt="" height="45" />{" "}
                      </Link>
                    </div>
                    <div className="auth-content my-auto">
                      <div className="text-center">
                        <h5 className="mb-0">{props.t("Registar Conta")}</h5>
                        <p className="text-muted mt-2">
                          {props.t("Obtenha sua conta Aime agora.")}
                        </p>
                      </div>
                      <Form
                        className="needs-validation custom-form mt-4 pt-2"
                        onSubmit={(e) => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                      >
                        {msg && status == "success" ? (
                          <Alert color="success">
                            {msg} <Link to="/aime">- Entrar</Link>
                          </Alert>
                        ) : null}

                        {msg && status == "danger" ? (
                          <Alert color="danger">{msg}</Alert>
                        ) : null}

                        <div className="mb-3">
                          <Label className="form-label">
                            {props.t("Email")}
                          </Label>
                          <Input
                            id="email"
                            name="email"
                            className="form-control"
                            placeholder={props.t("Digite o email")}
                            type="email"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.email || ""}
                            invalid={
                              validation.touched.email &&
                              validation.errors.email
                                ? true
                                : false
                            }
                          />
                          {validation.touched.email &&
                          validation.errors.email ? (
                            <FormFeedback type="invalid">
                              {validation.errors.email}
                            </FormFeedback>
                          ) : null}
                        </div>

                        <div className="mb-3">
                          <Label className="form-label">
                            {props.t("Nome")}
                          </Label>
                          <Input
                            name="name"
                            type="text"
                            placeholder={props.t("Digite o Nome")}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.name || ""}
                            invalid={
                              validation.touched.name && validation.errors.name
                                ? true
                                : false
                            }
                          />
                          {validation.touched.name && validation.errors.name ? (
                            <FormFeedback type="invalid">
                              {validation.errors.name}
                            </FormFeedback>
                          ) : null}
                        </div>
                        <div className="mb-3">
                          <Label className="form-label">
                            {props.t("Senha")}
                          </Label>
                          <Input
                            name="password"
                            type="password"
                            placeholder={props.t("Digite a senha")}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.password || ""}
                            invalid={
                              validation.touched.password &&
                              validation.errors.password
                                ? true
                                : false
                            }
                          />
                          {validation.touched.password &&
                          validation.errors.password ? (
                            <FormFeedback type="invalid">
                              {validation.errors.password}
                            </FormFeedback>
                          ) : null}
                        </div>
                        <div className="mb-4">
                          <p className="mb-0">
                            {props.t(
                              "Ao se registrar você concorda com o Aime"
                            )}{" "}
                            <a href="https://hubes.ai/aime/pt/terms&conditions" rel="noreferrer" className="text-primary">
                              {props.t("Termos de uso")}
                            </a>
                          </p>
                        </div>
                        <div className="mb-3">
                          <button
                            className="btn btn-primary w-100 waves-effect waves-light"
                            type="submit"
                            disabled={isLoading}
                          >
                            {isLoading ? (
                              <Spinner
                                size="sm"
                                className="ms-1"
                                style={{ color: "#FFF", marginTop: "3px" }}
                              />
                            ) : (
                              props.t("Registrar")
                            )}
                          </button>
                        </div>
                      </Form>

                      <div className="mt-5 text-center">
                        <p className="text-muted mb-0">
                          {props.t("Já tem uma conta?")}{" "}
                          <Link
                            to="/login"
                            className="text-primary fw-semibold"
                          >
                            {" "}
                            {props.t("Entrar")}{" "}
                          </Link>{" "}
                        </p>
                      </div>
                    </div>
                    <div className="mt-4 mt-md-5 text-center">
                      <p className="mb-0">
                        © {new Date().getFullYear()}{" "}
                        {props.t("Aime. Feito com")}{" "}
                        <i className="mdi mdi-heart text-danger"></i> Essência
                        Digital
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <CarouselPage />
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withTranslation()(Register);
